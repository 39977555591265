import Responsive from './Responsive';
import SmoothScroll from './SmoothScroll';
// import Accordion from './Accordion';
// import Tab from './Tab';
import SpNavi from './SpNavi';
// import ToTop from './ToTop';
// import DropDownMenu from './DropDownMenu';
// import BlockLink from './BlockLink';
// import Gmap from './Gmap';
import bowser from 'bowser';
// import matchheight from 'jquery-match-height';
// import link_icon from './link_icon';
import Swiper from 'swiper';
import inview from 'jquery-inview';
import {TweenMax , TimelineMax} from "gsap";

if(bowser.msie === true) {
	let version = 'ie_'+Math.floor(bowser.version);
	$('body').addClass('ie '+version);
}else if(bowser.msedge === true){
	$('body').addClass('edge');
}else if(bowser.firefox === true){
	$('body').addClass('ff');
}else if(bowser.tablet === true){
	$('body').addClass('tablet');
}
if(bowser.mobile === true){
	$('body').addClass('device--mobile');
	if(bowser.android === true) {
		$('body').addClass('device--mobile--android');
	}
}

let os, ua = navigator.userAgent;
if (ua.match(/Win(dows )?NT 10\.0/)) {
	os = "win10";
}
else if (ua.match(/Win(dows )?NT 6\.3/)) {
	os = "win8_1";
}
else if (ua.match(/Win(dows )?NT 6\.2/)) {
	os = "win8";
}
else if (ua.match(/Win(dows )?NT 6\.1/)) {
	os = "win7";
}else{
	os = '';
}

$('body').addClass(os);
/* ===============================================
Check responsive state
=============================================== */
// const r = new Responsive();

// $(window).on('resize',function(){
// check is responsive;
// 	console.log(r.state()());
// });
// check is mobile;
// alert(r.isPhone());
/* ===============================================
SP Navi
=============================================== */
//通常盤
let param = {
	target:'#spnavi',
	trigger:'.btn_sp_navi',
	filter:'resposive_flg',
	speed:200
};
const spnavi = new SpNavi(param);
let clone_nav = () =>{
	if(!$(".spnavi .inner ul").length){
		$(".global_nav ul").clone().appendTo("#scroller .inner");
	}
	// $(".global_header").addClass("is-show");
	// $(".global_header-logo").removeClass("is-hide");
}
spnavi.exec(clone_nav);

//execに可変引数で処理を渡してナビの開閉時に処理させるサンプル
//例として、SPナビでアコーディオンを開くたびにiScrollをリセットする
// let spnav_accordion_1 = (scroller)=>{
// 	$('#scroller .inner > ul > li > span').off();
// 	$('#scroller .inner > ul > li > span').on('click',function(){
// 		let $parent = $(this).parent('li');
// 		let $child = $(this).next('.gnavi-child');
// 		let status = $child.css('display');

// 		if(status == 'block'){
// 			$child.slideUp(300,function(){
// 				scroller.refresh();
// 			});
// 			$parent.removeClass('active');
// 		}else{
// 			$child.slideDown(300,function(){
// 				scroller.refresh();
// 			});
// 			$parent.addClass('active');
// 		}

// 		return false;
// 	});
// }
// spnavi.exec(spnav_accordion_1);

/* ===============================================
Smooth Scroll
=============================================== */
const sms = new SmoothScroll();
sms.exec();
/* ===============================================
To Top
show/hide toggle
=============================================== */
// const totop = new ToTop('#totop','#global_footer',100,400);
// totop.exec();


/* ===============================================
#	tel link
=============================================== */
$(function(){
	const ua = navigator.userAgent;
	if(ua.indexOf('iPhone') > 0 || ua.indexOf('Android') > 0){
	    $('.tel-link').each(function(){
	        var str = $(this).text();
	        $(this).html($('<a>').attr('href', 'tel:' + str.replace(/-/g, '')).append(str + '</a>'));
	    });
	}
});

/* ===============================================
#	swiper
=============================================== */
//pleas yarn add swiper@3.4.2
var slide = new Swiper ('.slide', {
     loop: true,
     speed:1000,
     autoplay:2000,
     effect:"fade",
     nextButton: '.swiper-button-next',
     prevButton: '.swiper-button-prev',
     pagination:'.swiper-pagination',
});

/* ===============================================
#	top page only
=============================================== */
const gnavFadeIn = ()=>{
	$(".js-gnav-slidein").on("inview",function(){
		const _this = $(this);
		const targets = _this.children("li");
		TweenMax.staggerTo(targets,1,{css:{className:'+=is-show'}},0.06);
	});
}

$(".splash").on("inview",function(){
	const _this = $(this);
	const targets = _this.children("div");
	// const targets = targets.reverse();

	// TweenMax.staggerTo(targets,1,{css:{className:'+=is-show'}},0.08,function(){
	// 	$(".splash").addClass("is-hide");
	// 	gnavFadeIn();
	// });

	const tl = new TimelineMax({onComplete:function(){
		$(".splash").addClass("is-hide");
		gnavFadeIn();
	}});
	tl.to(".splash-01",0.4,{scaleX:0},0.1);
	tl.to(".splash-02",0.4,{scaleY:0},0.2);
	tl.to(".splash-03",0.4,{scaleY:0},0.3);
	tl.to(".splash-04",0.4,{scaleX:0},0.4);
	tl.to(".splash-05",0.4,{scaleY:0},0.5);
	tl.to(".splash-06",0.4,{scaleY:0},0.6);

});

const _w = $(window);
if($(".home_mv").length){
	_w.on("load scroll",function(){
		let scrl = _w.scrollTop();
		let mvH = $(".home_mv").height();
		if(scrl<mvH){
			$(".btn_sp_navi_wrap .line").addClass("is-white");
			// $(".global_header-logo").addClass("is-hide");
			$(".global_header-logo").removeClass("is-show");
			$(".global_header").removeClass("is-show");

		}else{
			$(".btn_sp_navi_wrap .line").removeClass("is-white");
			// $(".global_header-logo").removeClass("is-hide");
			$(".global_header-logo").addClass("is-show");
			$(".global_header").addClass("is-show");
		}
		// console.log({scrl,mvH});
	});

}
/* ===============================================
#	inview elements
=============================================== */

//topページ　テキスト一行ごとのスライドイン
$(".js-inview-slide-text-parent").on("inview",function(){
	if($(window).width() > 769){
		let targets = $(this).find(".js-inview-slide-oneline");
		let targets_sp = $(this).find(".js-inview-slide-paragraph");
		TweenMax.staggerTo(targets,1,{css:{className:'+=is-show'}},0.1);
		//sp側も表示済み処理
		$(targets_sp).addClass("is-show");
	}else{
		let targets = $(this).find(".js-inview-slide-paragraph");
		let targets_pc = $(this).find(".js-inview-slide-oneline");
		TweenMax.staggerTo(targets,1,{css:{className:'+=is-show'}},0.2);
		//pc側も表示済み処理
		$(targets_pc).addClass("is-show");
	}
});

//下層 テキスト段落ごとのスライドイン
$(".js-inview-slide-text").on("inview",function(){
	$(this).addClass("is-show");
});

//ボーダーアニメ
$(".js-inview-border").on("inview",function(){
	$(this).addClass("is-show");
});

//画像のフェードイン
$(".js-inview-show").on("inview",function(){
	$(this).addClass("is-show");
});

//フッターのスライドアップイン
$(".js-inview-slideup").on("inview",function(){
	$(this).addClass("is-show");
});

//コンテンツタイトルの1文字毎スライドアップイン
$(".js-inview-title").each(function(){
	let _this = $(this);
	let textArr = _this.text().split('');
	for(let i = 0;i < textArr.length ; i++){
		textArr[i] = "<span>"+textArr[i]+"</span>";
	}
	_this.html(textArr.join(""));

	_this.on("inview",function(){
		_this.addClass("is-show");
		let targets = _this.children("span");
		TweenMax.staggerTo(targets,1,{css:{className:'+=is-show'}},0.06);
	});
})

/* ===============================================
#	totop
=============================================== */
const totop = $("#totop");
_w.on("load scroll resize",function(){
	let threshold = $(".js-gotop-threshold").offset().top;
	let scrollTop = _w.scrollTop();
	let winHeight = _w.height();
	if(scrollTop  + (winHeight/2 + -40) > threshold){
		totop.addClass("_active");
	}else{
		totop.removeClass("_active");
	}
});

/**
 * logo effect
 */

if($('#myCanvas').length > 0){
	/**
	 * set stage
	 */
	// サイズを指定
	const width = 512;
	const height = 512;

	// レンダラーを作成
	const renderer = new THREE.WebGLRenderer({
		canvas: document.querySelector('#myCanvas'),
		alpha: true,
		antialias: true
	});
	renderer.setClearColor( 0xffffff, 0 );
	renderer.setSize(width, height);
	renderer.setPixelRatio(window.devicePixelRatio);

	/**
	 * set scene
	 */
	let scene;
	scene = new THREE.Scene();

	/**
	 * set camera
	 */
	const camera = new THREE.OrthographicCamera(-256, +256, 256, -256);
	camera.position.set(0, 0, 300);
	/**
	 * set image
	 */
	let texture = new THREE.TextureLoader().load('build/images/logo_mv_home_gray.png',
	(tex) => { // 読み込み完了時
	    // 平面
	    const geometry = new THREE.PlaneGeometry(width, height ,1 ,1);
	    // const material = new THREE.MeshPhongMaterial( {
	    // 	map:texture,
	    // 	color: 0xffffff,
		// 	// specular: 0x8b929b,
		// 	specular: 0xcccccc,
	    // 	alphaTest:0.1,
	    // 	side:THREE.DoubleSide
		// } );
		
	    const material2 = new THREE.MeshStandardMaterial( {
			transparent:true,
			side: THREE.DoubleSide,
			map:texture,
			alphaTest:0.2,
			color: 0xffffff,
			specular: 0xbcbdc0,
	    } );
	    const plane = new THREE.Mesh( geometry, material2 );
	    scene.add( plane );
	});

	/**
	 * set light effects
	 */
	// let PointLight = new THREE.PointLight(0x4c5766, 0.5);
	// PointLight.position.set(-30, 2, 2);
	// scene.add(PointLight);

	// let DirectionalLight = new THREE.DirectionalLight(0x67778c,0.4);
	// let DirectionalLight = new THREE.DirectionalLight(0x67778c,0.2);
	let DirectionalLight = new THREE.DirectionalLight(0xffffff,0.2);
	DirectionalLight.position.set(-8000, -120, 3000);
	scene.add(DirectionalLight);

	// let DirectionalLight2 = new THREE.DirectionalLight(0xFFFFFF,0.14);
  	// DirectionalLight2.position.set(0, 0, 3000);
	// scene.add(DirectionalLight2);

	let ambientlight = new THREE.AmbientLight(0xffffff,2);
	scene.add(ambientlight);

	/**
	 * rendar , frame animation
	 */
	let trans = 0;
	let stopframe = 0;
	const loopspeed = 150;
	tick();

	//fire on per frame
	function tick() {
		renderer.render(scene, camera);
		DirectionalLight.position.x += 40;
		if(DirectionalLight.position.x > 8000){
			DirectionalLight.position.x = -8000;
		}

		// // 角度に応じてカメラの位置を設定
		// //camera.position.x = 500 * Math.sin(Date.now() / 2000);
		// camera.position.x = 0;
		// camera.position.y = 250;
		// //camera.position.z = 500 * Math.cos(Date.now() / 2000);
		// camera.position.z = 400;
		// //console.log(camera.position.z);
		// // 原点方向を見つめる
		// camera.lookAt(new THREE.Vector3(0, 0, 0));

		// if(stopframe == 0){
		// 	PointLight.position.x += 0.2;
		// 	if(PointLight.position.x > 30){
		// 		PointLight.position.x = -30;
		// 		PointLight.position.y -= 1.5;

		// 		if(PointLight.position.y < -5){
		// 			PointLight.position.y = 5;
		// 		}
		// 		stopframe++;
		// 	}
		// }else if(stopframe > 0 && stopframe < loopspeed){
		// 	stopframe++;
		// }else if(stopframe == loopspeed){
		// 	stopframe = 0;
		// }
		requestAnimationFrame(tick);
	}
}

_w.on("load resize",function(){
	let winW = $(this).width();
	if(winW<768){
		$("#myCanvas").css({width:"260px",height:"260px"});
	}else{
		$("#myCanvas").css({width:"512px",height:"512px"});	
	}
})
/* ===============================================
# artists 暫定で非公開処理
=============================================== */
$(".home_artists a").on("click",function(){
	// return false;
});